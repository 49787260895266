<script lang="ts" setup>
import type { IPropsSharedKitDropItem } from '~/components/SharedKitDropItem/SharedKitDropItem.types';
import { generateMockData } from '~/utils/generate-mock.utils';
import { GlobalUtils } from '~/utils';
import SharedKitDropItem from '~/components/SharedKitDropItem/SharedKitDropItem.vue';
import image from '~/assets/images/Profile/Inventory/image.png';

definePageMeta({ layout: false });

const exampleLiteralValues = {
  shining: [true],
  image: [image],
  currency: ['RUB'],
  price: ['1', '10', '100', '1000', '10000', '1.856', '10.856', '100.856', '1000.856', '10000.856'],
};

const schema: IPropsSharedKitDropItem = {
  price: '0',
  color: '',
  disabled: false,
  id: 0,
  image: '',
  link: {
    external: false,
    target: '',
    to: '',
  },
  name: '',
  shining: false,
  shiningColor: '',
  noOverlay: false,
  type: 3,
  csInfo: {
    isSt: false,
    weaponName: '',
  },
};

const dropItemMock = generateMockData(schema, { literalValues: exampleLiteralValues });
</script>

<template>
  <LiveDropFeedController />
  <div class="test-page">
    <div class="test-page__item">
      <div>
        <div class="test-page__item-title">Базовый компонент дропа CS</div>
        <SharedKitDropItem
          :id="dropItemMock.id"
          :currency="dropItemMock.currency"
          :name="dropItemMock.name!"
          :image="dropItemMock.image!"
          :color="GlobalUtils.Colors.getColorsRarity('COVERT')"
          :price="dropItemMock.price!"
          :disabled="dropItemMock.disabled"
          :link="dropItemMock.link"
          :shining="dropItemMock.shining"
          :shining-color="GlobalUtils.Colors.getColorsRarity('COVERT')"
          :type="dropItemMock.type"
          :cs-info="dropItemMock.csInfo"
        />
      </div>
    </div>

    <div>
      <div class="test-page__item-title">Дроп шмотка на странице /open/casename/dropid</div>
      <BigItemWrapper state="drop">
        <SharedKitDropItem
          :id="dropItemMock.id"
          :currency="dropItemMock.currency"
          :name="dropItemMock.name!"
          :image="dropItemMock.image!"
          :color="GlobalUtils.Colors.getColorsRarity('COVERT')"
          :price="dropItemMock.price!"
          :disabled="dropItemMock.disabled"
          :link="dropItemMock.link"
          :shining="dropItemMock.shining"
          :shining-color="GlobalUtils.Colors.getColorsRarity('COVERT')"
          :cs-info="dropItemMock.csInfo"
          no-overlay
        >
          <template #top-right>
            <SvgoShareIcon />
          </template>
        </SharedKitDropItem>
      </BigItemWrapper>
    </div>

    <div>
      <div class="test-page__item-title">Ладдер шмотка на странице ладдеров</div>
      <BigItemWrapper state="ladder" position="1">
        <SharedKitDropItem
          :id="dropItemMock.id"
          :currency="dropItemMock.currency"
          :name="dropItemMock.name!"
          is-right-price
          :image="dropItemMock.image!"
          :color="GlobalUtils.Colors.getColorsRarity('COVERT')"
          :price="dropItemMock.price!"
          :disabled="dropItemMock.disabled"
          :link="dropItemMock.link"
          :shining="dropItemMock.shining"
          :shining-color="GlobalUtils.Colors.getColorsRarity('COVERT')"
          :cs-info="dropItemMock.csInfo"
          no-overlay
        />
      </BigItemWrapper>
    </div>
  </div>
</template>

<style lang="scss" scoped src="./test.scss" />
