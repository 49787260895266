type TIsPrimitive = string | number | boolean | null;

type TLiterals = string | number | boolean;

/**
 * Опции для генерации моковых данных.
 */
export interface IMockOptions {
  arrayLength?: number;
  literalValues?: Record<string, TLiterals[]>;
}

/**
 * Проверяет, является ли значение примитивом.
 *
 * @param value - Проверяемое значение.
 * @returns true, если значение является примитивом.
 */
function isPrimitive(value: unknown): value is TIsPrimitive {
  return value === null || typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean';
}

/**
 * Генерирует случайное значение из набора литералов.
 *
 * @param literals - Массив литералов.
 * @returns Случайное значение из массива.
 */
function generateLiteralValue(literals: TLiterals[]): TLiterals {
  return literals[Math.floor(Math.random() * literals.length)];
}

/**
 * Генерирует случайное примитивное значение.
 *
 * @param value - Тип примитива.
 * @returns Сгенерированное значение.
 */
function generatePrimitiveValue(value: TIsPrimitive): TIsPrimitive {
  switch (typeof value) {
    case 'string':
      return `mocked_${Math.random().toString(36).substring(7)}`;
    case 'number':
      return Math.floor(Math.random() * 100);
    case 'boolean':
      return Math.random() < 0.5;
    default:
      return null;
  }
}

/**
 * Генерирует моковые данные на основе структуры объекта.
 *
 * @template T - Тип данных.
 * @param example - Объект-шаблон для генерации моков.
 * @param options - Опции для генерации (например, длина массивов и литералы для конкретных полей).
 * @returns Сгенерированные моковые данные.
 */
export function generateMockData<T>(example: T, options: IMockOptions = {}): Partial<T> {
  /**
   * Генерирует моковые значения на основе типа.
   *
   * @param value - Значение для генерации.
   * @param key - Ключ для доступа к литералам (если применимо).
   * @returns Сгенерированное значение.
   */
  const generateMockValue = (value: unknown, key?: string): unknown => {
    if (key && options.literalValues && options.literalValues[key]) {
      return generateLiteralValue(options.literalValues[key]);
    }

    if (isPrimitive(value)) {
      return generatePrimitiveValue(value as TIsPrimitive);
    }

    if (Array.isArray(value)) {
      const length = options.arrayLength ?? Math.floor(Math.random() * 5 + 1);
      return Array.from({ length }, () => generateMockValue(value[0]));
    }

    if (typeof value === 'object' && value !== null) {
      const mockObj: Record<string, unknown> = {};
      for (const [k, v] of Object.entries(value as Record<string, unknown>)) {
        mockObj[k] = generateMockValue(v, k);
      }
      return mockObj;
    }

    return undefined;
  };

  return generateMockValue(example) as Partial<T>;
}

// Пример абстрактного интерфейса
export interface IExampleData {
  details: {
    count: number;
    description: string;
  };
  id: number;
  isActive: boolean;
  name: string;
  tags: string[];
  values: number[];
}

/**
 * Пример использования функции generateMockData
 *
 * const exampleData: IExampleData = {
 *   details: {
 *     count: 0,
 *     description: '',
 *   },
 *   id: 0,
 *   isActive: false,
 *   name: '',
 *   tags: [''],
 *   values: [0],
 * };
 *
 * // Генерация моков на основе структуры exampleData
 * const mockData = generateMockData(exampleData);
 *
 * Примерный результат выполнения:
 * {
 *   id: 52,
 *   name: 'mocked_3g6h8',
 *   isActive: true,
 *   details: {
 *     description: 'mocked_r8ksj',
 *     count: 74,
 *   },
 *   tags: ['mocked_lj2k5', 'mocked_a1m4p'],
 *   values: [27, 63, 85]
 * }
 */
